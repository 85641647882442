@media screen and (max-width: 768px) {
  .user_profile_pic {
    width: 70% !important;
  }

  .profile-card .fa-edit {
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    top: 222px;
    left: 257px;
  }
}

@media screen and (min-width: 767px) {
  .profile-card .fa-edit {
    margin: 0 80%;
    cursor: pointer;
    font-size: 18px;
  }
}

@media screen and (max-width: 376px) {
  .profile-card .fa-edit {
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    top: 222px;
    left: 207px;
  }
}

.download_pdf {
  background-color: #001787;
  border-color: #001787;
  color: #fff;
  margin-top: 10px;
}

@media screen and (max-width: 2561px) {
  .download_pdf {
    margin-left: 1616px;
  }
}

@media screen and (width: 2560px) {
  .download_pdf {
    margin-left: 1530px;
  }
}

@media screen and (max-width: 1920px) {
  .download_pdf {
    margin-left: 1205px;
  }
}

@media screen and (max-width: 1441px) {
  .download_pdf {
    margin-left: 975px;
  }
}

@media screen and (max-width: 1026px) {
  .download_pdf {
    margin-left: 765px;
  }
}

@media screen and (max-width: 769px) {
  .download_pdf {
    margin-left: 630px;
  }
}

@media screen and (max-width: 426px) {
  .download_pdf {
    margin-left: 290px;
  }
}

@media screen and (max-width: 376px) {
  .download_pdf {
    margin-left: 240px;
  }
}

.invoice-wrap {
  width: fit-content;
}

.footer-about {
  padding-right: 4rem;
}

.nav-link.nav_type {
  background: #e5efef;
  cursor: pointer;
}

.nav-link.nav_type span {
  color: #000;
}

nav .nav-link.nav_type.active span {
  color: #f7f7f7;
}

.nav-link.nav_type.active {
  background: #001787;
  /* margin: 0 3px; */
}

.duration_flight {
  font-size: 15px !important;
}

.img-fluid-custom {
  width: 90%;
  height: auto;
}

@media screen and (max-width: 1024px) {
  .img-fluid-custom {
    max-width: 304px;
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 700px) {
  .price-filter {
    font-size: 18px;
  }
}

.baggage_info_for_link {
  text-align: center;
  justify-content: center;
  /* border: 1px solid #000; */
  padding: 18px 0;
}
