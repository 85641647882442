@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:wght@400;700&display=swap');

.__container{
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Atkinson Hyperlegible',
  sans-serif;
  color: white;
  font-size: 60px;
  text-align: center;
  background: url('./plane.jpg') center;
}

._uc{
  font-size: 24px;
  font-weight: 600;
}