@media only screen and (max-width: 1023px) {
  ._card{
    min-width: 350px !important;
  }
}

@media only screen and (max-width: 995px) {
  ._card {
    min-width: 390px !important;
  }
}

@media only screen and (max-width: 768px) {
  ._card {
    min-width: 350px !important;
  }
}