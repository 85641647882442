@media screen and (max-width: 480px) {
  h6.list-hidden.mb-1.fs-13.font-weight-500.text-primary._text-center,
  h4.mb-4._text-center {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .main-price {
    font-size: 18px;
  }

  .see-flight {
    display: none;
  }

  .list-item_logo img {
    height: 60px;
  }

  .__image-name {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  ._img {
    height: 50px !important;
    width: 70px !important;
  }

  .fs-13-1 {
    font-size: 20px !important;
  }

  ._vax {
    text-align: center;
  }

  ._vax_x {
    display: flex;
    justify-content: center;
  }
}
