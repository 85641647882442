.google-button{
  font-size: 16px !important;
}

.fb-button{
  width: 275px;
  padding: 13px;
  border: none;
  border-radius: 4px;
  margin: 10px 0;
  opacity: 0.85;
  display: inline-block;
  font-size: 16px;
  font-family: 'Roboto',
  sans-serif;
  line-height: 15px;
  text-decoration: none;
  /* remove underline from anchors */
  background-color: #3b5998;
  color: white;
}
