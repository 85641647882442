@media only screen and (min-width: 600px) {
  ._main {
    margin-top: 15px;
  }

  /* ._rs{
    margin-top: 130px;
  } */
}

@media only screen and (max-width: 600px) {
  ._main {
    margin-top: 8px;
  }

  .movePlace {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .__search-box-container,
  .search-box_col {
    padding: 3px !important;
  }
}

@media only screen and (max-width: 1023px) {
  ._rs_mobile {
    display: flex !important;
  }
  .travellers-dropdown .dropdown-toggle {
    margin-left: 21px;
  }
}

._rs_mobile {
  display: none;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  min-height: 49px !important;
}

.css-1wa3eu0-placeholder,
.DateInput_input {
  font-weight: 400;
  font-size: 17px;
  color: #484848;
  background-color: transparent;
}

.DateRangePicker {
  width: 100%;
}

.DateRangePickerInput__withBorder,
.DateInput {
  border-radius: 4px !important;
}

.DateRangePickerInput__withBorder {
  border-color: hsl(0, 0%, 80%);
  display: flex;
  align-items: center;
}

.search-select .custom-select {
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #fff no-repeat right 0.25rem center/14px 16px;
  border: 0;
  font-weight: 500;
  font-size: 15px;
  color: #616161;
  border-radius: 0;
  padding: 0;
  margin-right: 5px;
}

.header-three .search-select .custom-select {
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  font-size: 14px;
  font-weight: 400;
  background: rgba(255, 255, 255, 0.2) no-repeat right 0.35rem center/14px 16px;
  padding-left: 8px;
}

.search-select .custom-select option {
  padding: 5px;
}

.DateInput_input {
  width: 152px;
}

.SingleDatePickerInput__withBorder {
  width: 303px;
}

._lap_btn {
  margin-left: 40px;
}

@media only screen and (max-width: 1024px) {
  ._lap_btn {
    margin-left: 80px;
  }
}

@media only screen and (max-width: 991px) {
  ._lap_btn {
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  ._new_liner {
    display: none !important;
  }
}
