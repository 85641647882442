.verification-content {
  background-color: #f7f7f7;
}
.verification-card {
  border-radius: 9px;
}

.avatar-xxl.avatar-4by3 {
  width: 10.5rem;
}
.otp-form input {
  width: 50px;
  height: 70px;
  padding: 0;
  margin-right: 20px;
  text-align: center;
  border-radius: 9px;
  border: 1px solid #c9d2e3;
  /* box-shadow: 0 0 5px #ccc inset; */
  font-size: 19px;
}
.fs-21 {
  font-size: 21px !important;
}
.vh100 {
  height: 100vh;
}
.login {
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ebeef4;
  padding: 20px;
}
.login .login-content {
  max-width: 35rem;
  margin: 0 auto;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.login .login-content.login-content_bg {
  max-width: 26rem;
  background-color: #fff;
  padding: 50px;
  border-radius: 9px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.login .form-control {
  border: 1px solid #c9d2e3;
  height: 45px;
  border-radius: 9px;
}
.fs-15px {
  font-size: 15px !important;
}
.login label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: 600;
}
.custom-btn {
  font-size: 18px;
  border-radius: 9px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn-block {
  display: block;
  width: 100%;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
