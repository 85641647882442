.footer-menu {}

/* .footer-menu li {
    cursor: pointer;
} */

.footer-menu li a {
    font-weight: 700;
    transition: all;
    transition-delay: 150;
}

.footer-menu li:hover a {
    color: #FF0000 !important;
}

.footer-menu li a:active {
    color: #FF0000 !important;
}

.text-decoration-none {
    text-decoration: none;
    color: inherit;
}

.call-to-action {
    display: inline-block;
    margin-bottom: 0;
    color: rgb(239, 255, 16);
    /* text-transform: uppercase; */
    font-weight: 700;
    letter-spacing: 0.031rem;
    font-size: .9rem;
    cursor: pointer;
    transition: all 150ms ease-in-out;
}

.call-to-action:last-child {
    margin-top: 10px;
}

.call-to-action:hover {
    color: #FF0000;
}