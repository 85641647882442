.search-container {
  margin: 50px 0;
}

.search-container h4 {
  font-size: 22px;
  line-height: 20px;
  font-weight: 600;
  color: #1c3c6b;
  text-align: center;
  margin-bottom: 12px;
}

.search-container .wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

select.brand-input {
  /* appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none; */
  background-position-x: 244px;
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  font-size: 14px;
  line-height: 22px;
  color: #3a4856;
  padding: 8px 16px;
  width: 100%;
  /* background: #f5f5f5 url(icon-arrow-down-grey.a1230f06.svg) no-repeat calc(100% - 15px) 50%; */
}

.search-container .wrapper .btn-wrapper {
  position: relative;
  margin-left: 12px;
}

.brand-btn {
  background-color: #056998;
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  padding: 6px 20px;
  border: 1px solid #056998;
  position: relative;
  border-radius: 5px;
}

.search-container .wrapper .btn-wrapper button.brand-btn svg {
  display: none;
}

.textBox-container {
  margin: 50px 0 50px;
  background-color: #f5f5f5;
}

.textBox-container .country-image {
  position: relative;
  border-radius: 3px;
}

.textBox-container .visa-info-text {
  color: #1c3c6b;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  padding: 44px 24px 24px;
  z-index: 2;
  text-align: left;
  border-radius: 5px;
}

@media screen and (max-width: 576px) {
  .textBox-container .visa-info-text {
    padding: 5px 12px 12px;
  }
}

.documents .header-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.documents h4,
.payment h4,
.validity h4 {
  margin: 12px 0;
  font-size: 22px;
  line-height: 20px;
  font-weight: 600;
  color: #1c3c6b;
}

.documents .header-wrapper a {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-decoration: underline;
  text-align: center;
  color: #056998;
  padding: 2px 8px;
  border-radius: 5px;
}

.documents .header-wrapper a img {
  height: 30px;
}

.documents p,
.payment p,
.validity p {
  margin: 5px 0 8px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
}