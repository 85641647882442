@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap');

@media only screen and (max-width: 600px) {
  ._scroll {
    overflow: scroll;
  }
}

.__top_container {
  font-size: 16px;
  font-family: 'Open Sans',
    sans-serif;
  font-weight: 600;
}

.table__header th {
  padding: 10px;
  font-weight: 500;
}