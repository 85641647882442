.navbar {
  z-index: 10;
}

.navbar-bg-white.navbar-expand-lg .navbar-nav .nav-link:hover {
  color: #001b8d;
  opacity: 0.9;
  transition: 0.5s ease-in-out;
}
.nav_item:hover {
  border-bottom: 2px solid #000;
  transition: all 0;
}
