.flight_card_container {
  display: flex;

  flex-direction: column;
  gap: 10px;
}
.flight_card {
  padding: 35px;
  /* width: 100%; */
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 8px 24px hsla(210, 8%, 62%, 0.2);
}

.flight_logo {
  width: 80px;
  height: 100%;
  display: block;
}

.layover_time {
  background-color: #eaedf1;
  border-radius: 20px;
  padding: 5px 10px;
  width: fit-content;
  height: 100%;
  margin-top: 12px;
}
.layover_time p {
  font-size: 12px;
  color: #000;
  font-weight: 500;
  margin: 0;
}

.book_btn {
  width: 150px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  box-shadow: 0px 0px 10px 0px #e0e0e0;
  border: none;
  background: #001787;
  color: #fff;
}

.round_trip_border:nth-child(1) {
  border-bottom: 1px solid #e0e0e0;
}
.card_border {
  border-left: 1px solid #e5e5ee;
  border-right: 1px solid #e5e5e5;
}
.fare_text {
  font-size: 30px;
  color: #000;
  font-weight: 600;
  margin: 0;
}
.fare_text_discounted {
  font-size: 25px;
  font-weight: 600;
  margin: 0;
  text-decoration: line-through;
}
@media screen and (max-width: 1024px) {
  .flight_card_container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .card_border {
    border: none;
  }
  .fare_text {
    font-size: 20px;
  }
  .fare_text_discounted {
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  .flight_card_container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .flight_card {
    padding: 20px;

    justify-content: center;
  }
  .flight_logo {
    width: 60px;
  }
  .layover_time {
    padding: 5px 5px;
  }
  .layover_time p {
    font-size: 10px;
  }
  .book_btn {
    width: 100px;
    height: 30px;
  }
  .card_border {
    border: none;
  }
  .fare_text {
    font-size: 15px;
  }
  .fare_text_discounted {
    font-size: 12px;
  }
}
