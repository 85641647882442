@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');

.search-box_content {
  font-family: 'Outfit', sans-serif;
  height: 750px;
  position: relative;
}

.search-box_content::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-color: #00000040;
  position: absolute;
  top: 0;
  left: 0;
}

.search-box_content__slider {
  width: 100%;
  height: 100%;
}

.search-box_content__slider .carousel-initialized,
.search-box_content__slider .carousel-track {
  height: 100%;
}

.search-box_content__slider .home-slider-img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.search-box-container {
  z-index: 1;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  min-height: 49px !important;
  cursor: pointer !important;
}

.css-1wa3eu0-placeholder,
.DateInput_input {
  font-weight: 400;
  font-size: 17px;
  color: #484848;
  background-color: transparent;
  cursor: pointer;
}

.DateRangePicker {
  width: 100%;
}

.DateRangePickerInput__withBorder {
  border-color: hsl(0, 0%, 80%);
  display: flex;
  align-items: center;
}

.search-select .custom-select {
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #fff no-repeat right 0.25rem center/14px 16px;
  border: 0;
  font-weight: 500;
  font-size: 15px;
  color: #616161;
  border-radius: 0;
  padding: 0;
  margin-right: 5px;
}

.header-three .search-select .custom-select {
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  font-size: 14px;
  font-weight: 400;
  background: rgba(255, 255, 255, 0.2) no-repeat right 0.35rem center/14px 16px;
  padding-left: 8px;
}

.search-select .custom-select option {
  padding: 5px;
}

.DateInput_input {
  width: 300px !important;
}

.SingleDatePickerInput__withBorder {
  width: 300px;
}

.DateInput_input__focused {
  width: 100% !important;
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #008489;
  border-left: 0;
}

.DateInput_1 {
  width: 155px;
  overflow: hidden;
}

.DateRangePickerInput__withBorder {
  width: 338px;
}

._lap_btn_r {
  margin-left: 45px;
  width: 86%;
}

._scs:hover {
  cursor: pointer;
}

.tra-box {
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 13px;
}

.tra-boxx {
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 13px;
}

@media only screen and (max-width: 1399px) {
  .SingleDatePickerInput__withBorder {
    width: 235px !important;
  }

  .DateRangePickerInput__withBorder {
    width: 310px;
  }

  ._hide {
    display: none;
  }

  ._lap_btn_r {
    margin-left: 55px;
  }
}

@media only screen and (max-width: 1199px) {
  .SingleDatePickerInput__withBorder {
    width: 215px !important;
  }

  .DateRangePickerInput__withBorder {
    width: 310px;
  }

  ._hide {
    display: none;
  }

  ._lap_btn_r {
    margin-left: 95px;
    width: 135px;
  }
}

@media only screen and (max-width: 1024px) {
  .DateRangePickerInput__withBorder {
    width: 300px;
  }

  ._hide {
    display: none;
  }

  ._lap_btn_r {
    margin-left: 90px;
  }
}

@media only screen and (max-width: 991px) {
  ._lap_btn_r {
    width: 100%;
    margin-left: 0px;
  }

  .SingleDatePickerInput__withBorder {
    width: 313px !important;
  }
}

@media only screen and (max-width: 767px) {
  .travellers-dropdown {
    min-width: 295px !important;
  }

  .SingleDatePickerInput__withBorder {
    width: 348px !important;
  }

  ._hide {
    display: inline-block;
  }

  ._lap_btn_r {
    margin-left: 0;
    width: 100%;
  }
}

/* @media only screen and (max-width: 765px) {
 
} */

@media only screen and (max-width: 425px) {
  .DateRangePickerInput__withBorder {
    width: 349px;
  }

  ._lap_btn_r {
    margin-left: 0;
    width: 100%;
  }

  .travellers-dropdown {
    width: 345px !important;
  }
}

@media only screen and (max-width: 375px) {
  .SingleDatePickerInput__withBorder {
    width: calc(100vw - 65px) !important;
  }

  .DateRangePickerInput__withBorder {
    width: calc(100vw - 65px);
  }

  .travellers-dropdown {
    width: 350px !important;
  }
}

.DateRangePickerInput__withBorder,
.DateInput {
  border-radius: 4px !important;
}

.oneWayTravellerMobile {
  display: none;
}

.roundtripTravellerMobile {
  display: none;
}

@media only screen and (max-width: 425px) {
  .oneWayTraveller {
    display: none !important;
  }

  .oneWayTravellerMobile {
    display: block !important;
  }

  .roundtripTraveller {
    display: none !important;
  }

  .roundtripTravellerMobile {
    display: block;
  }
}